<template lang="pug">
    v-card.custom-card-profile.pb-5
        .custom-card-profile__avatar
            v-avatar
                img(:src="profile && profile.image ? profile.image : userImage" alt="John")
        .custom-card-profile__settings(@click="$emit('openUpdate')")
            img(src="@/assets/images/settings-icon.png")
        h2.text-center.custom-card-profile__username {{profile.first_name}} {{profile.last_name}}
        span(v-if="tokens && userType === 'bussiness'")
          h4.text-center.grey--text Remaining downloads
          p.text-center.grey--text Image {{tokens.image}}
          p.text-center.grey--text Video {{tokens.video}}
        v-container
            //- v-row(v-if="profile.roles.length >= 2")
                v-col(cols="12" md="6" v-for="role in profile.roles").text-center
                    v-btn(v-if="rol !== role" color="primary" small flat @click.stop='changeRol(role)')
                        | Change to {{role}}
            h2.text-center.custom-card-profile__usertype {{profile.role}}
</template>

<script>
  import userImage from '@/assets/user.png'
  export default {

    props: ['profile', 'updateDialog', 'userType'],

    data () {
      return {
        userImage: userImage,
      }
    },

    computed: {
      rol () {
        return this.$store.getters['users/getRole']
      },
      tokens () {
        return this.$store.getters['downloads/getTokens']
      },
    },

    methods: {
      changeRol (type) {
        const vm = this
        vm.$store.dispatch('users/updateRole', type).then(() => {
          // vm.onSuccess()
        })
        if (type === 'Contributor') {
          this.$router.push('/contributor/home')
        } else {
          this.$router.push('/')
        }
      },
    },

  }
</script>

<style lang="scss" scoped>
    .custom-card-profile{
        margin-top: 50px;
        border-radius: 17px !important;
        position: relative;
        .btn-rol{
            font-size: 12pt;
            padding-top: .5rem;
            padding-bottom: .5rem;
            height: 50px;
        }
        &__settings{
            position: absolute;
            top: 1rem;
            right: 1rem;
            width: 25px;
            height: auto;
        }
        &__avatar{
            width: 150px;
            height: 150px;
            background-color: #eee;
            border-radius: 50% !important;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto -50px;
            transform: translateY(-50%);
            .v-avatar{
            width: 90% !important;
            height: 90% !important;
            }
            img{
            border: 5px solid var(--font-color-2);
            }
        }
        &__username,
        &__usertype {
            color: var(--font-color-2);
        }
        &__usertype{
            font-weight: ligth;
    }
}
</style>
